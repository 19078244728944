import React, { ReactElement } from 'react';
import Template from './layout/template';
import CallToActionSupportSection from './sections/call-to-action-support-section';
import SolutionsHeroSection from './sections/solutions-hero-section';

export default function Maintenance(): ReactElement {
  return (
    <Template>
      <SolutionsHeroSection
        title="Maintenance."
        description={{
          short: 'Please check back soon.',
          long: 'Out site is currently in maintenance mode, please check back soon.',
        }}
        imageUrl="/assets/images/404.jpg"
        includeLinks={false}
      />
      <CallToActionSupportSection />
    </Template>
  );
}
